export default {
  msg_login_form_title: 'Prisijungimas',

  msg_no_items_found: 'Įrašų nerasta',

  msg_value_yes: 'Taip',
  msg_value_no: 'Ne',

  //calendar
  msg_time: 'Laikas',

  //confirm
  msg_confirm_delete: 'Ar tikrai norite pašalinti įrašą?',

  //Archived
  msg_label_archived: 'Archyvuotas',
  msg_label_non_archived: 'Nearchyvuotas',
  msg_label_without_league_group: 'Be lygų grupių',

  //Validations
  msg_error_field_invalid_email: 'Netesingas el. paštas',
  msg_error_field_required: 'Privalomas laukas',
  msg_error_field_invalid_number: 'Neteisingas skaičius',

  //Login
  msg_login_submit_button: 'Prisijungti',
  msg_forgot_password_text: 'Pamiršai slaptažodį?',
  msg_forgot_password_link: 'Primink',

  //Forgot password
  msg_forgot_password_form_title: 'Slaptažodžio priminimas',
  msg_forgot_password_submit_button: 'Primink',
  msg_login_link: 'Prisijungti',
  msg_forgot_password_success: 'Instrukcijos išsiųstos į el. paštą',

  //Errors
  msg_error_unexpected: 'Netikėta klaida. Mėginkite dar kartą',
  msg_error_invalid_credentials: 'Neteisingi prisijungimo duomenys',
  msg_error_invalid_date_range: 'Neteisingas datos rėžis',
  msg_btn_reload: 'Perkrauti puslapį',

  //Table
  msg_table_header_league: 'Lyga',
  msg_table_header_date: 'Data',
  msg_table_header_time: 'Laikas',
  msg_table_header_teams: 'Komandos',
  msg_table_header_address: 'Adresas',
  msg_table_header_referees: 'Teisėjai',
  msg_table_header_secretary: 'Sekretoriato darbuotojai',
  msg_table_header_other_participants: 'Kiti dalyviai',
  msg_table_header_game_number: 'Nr.',
  msg_table_header_user_type_in_event: 'Tipas',
  msg_table_header_ranking: 'Reitingas',
  msg_table_header_game_count: 'Rungtynių skaičius',
  msn_table_no_records: 'Nėra įrašų',

  //Posts
  msg_posts_header: 'Naujienos',
  msg_field_content: 'Turinys',
  msg_post_field_send_mail: 'Išsiųsti laišką?',
  msg_post_field_important: 'Svarbi naujiena?',
  msg_label_new_post: 'Sukurti naujieną',
  msg_label_edit_post: 'Redaguoti naujieną',

  //My games
  msg_my_games_header: 'Mano tvarkaraštis',

  //Users
  msg_users_header: 'Vartotojai',

  //Full schedule
  msg_full_schedule_header: 'Pilnas tvarkaraštis',
  msg_button_export_from_selected_month_schedule: 'Eksportuoti tvarkaraštį {{year}}-{{month}}',
  msg_button_export_selected_month_schedule: 'Eksportuoti tvarkaraštį nuo {{year}}-{{month}}',

  //leagues
  msg_leagues_header: 'Lygos',
  msg_league_field_code: 'Kodas',
  msg_league_field_league_group: 'Lygų grupė',

  msg_league_form_data_fields: 'Lygos duomenys',
  msg_league_form_settings_fields: 'Lygos nustatymai',
  msg_league_field_in_cash: 'Grynieji?',
  msg_league_field_is_small: 'Mažoji lyga?',
  msg_league_field_payed_by_other: 'Moka kiti?',
  msg_league_field_invoiceable: 'Reikalinga sąskaita?',
  msg_league_field_split_rankings: 'Išskirtyti reitingus?',
  msg_league_form_ranking_fields: 'Reitingas',

  //League groups
  msg_league_group_field_is_archived: 'Archyvuota?',
  msg_league_group_field_leagues: 'Lygos',
  msg_league_groups_header: 'Lygų grupės',
  msg_label_league_group_info: 'Lygos grupės duomenys',
  msg_label_edit_league_group: 'Redaguoti lygų grupę',
  msg_label_new_league_group: 'Nauja lygų grupė',
  msg_league_group_form_settings_fields: 'Nustatymai',
  msg_league_group_field_balance_calculation: 'Balanso skaičiavimas',

  //Game trip ranking
  msg_game_trip_rankings_groups_header: 'Kelionės reitingai',
  msg_label_new_game_trip_ranking: 'Naujas kelionės reitingas',
  msg_label_edit_game_trip_ranking: 'Redaguoti kelionės reitingą',
  msg_game_trip_ranking_form_data_fields: 'Kelionės reitingas',
  msg_game_trip_ranking_form_ranking_fields: 'Reitingai',

  //League filters
  msg_label_leagues_normal: 'Paprasta lyga',
  msg_label_leagues_small: 'Mažoji lyga',
  msg_label_leagues_all: 'Visos lygos',

  //Availability
  msg_my_availability_header: 'Mano užimtumas',
  msg_availability_start_date: 'Pradžia',
  msg_availability_end_date: 'Pabaiga',
  msg_availability_comment: 'Komentaras',
  msg_availability_is_unavailable: 'Užsiėmęs?',
  msg_availability_modal_edit_title: 'Redaguoti užimtumą',
  msg_availability_modal_create_title: 'Naujas užimtumas',
  msg_availability_form_repeat_section: 'Pasikartojimas',
  msg_use_availability_header: 'Vartotojų užimtumas',
  msg_user_availabilities_modal_title: 'Užimtumas {{date}}',
  msg_user_availabilities_unavailable_title: 'Užsiėmę',
  msg_user_availabilities_available_title: 'Kiti komentarai',
  msg_game_nomination_header: 'Rungtynių paskyrimas',

  //Button
  msg_button_load_more: 'Įkelti daugiau įrašų',
  msg_button_remove: 'Ištrinti',
  msg_button_save: 'Išsaugoti',
  msg_button_add: 'Pridėti naują',
  msg_button_export: 'Eksportuoti',
  msg_button_export_with_date: 'Išrašas {{year}}-{{month}}',
  msg_button_new_import: 'Įkelti išnaujo',
  msg_button_edit: 'Redaguoti',
  msg_button_delete: 'Ištrinti',

  //Sidebar
  msg_sidebar_new_item: 'Naujas',
  msg_sidebar_items_list: 'Sąrašas',
  msg_sidebar_home_page: 'Pradžia',
  msg_sidebar_users_page: 'Vartotojai',
  msg_sidebar_leagues_page: 'Lygos',
  msg_sidebar_league_groups_page: 'Lygų grupės',
  msg_sidebar_game_trip_rankings_page: 'Kelionės reitingai',
  msg_sidebar_schedule_page: 'Tvarkaraštis',
  msg_sidebar_my_schedule: 'Mano tvarkaraštis',
  msg_sidebar_full_schedule: 'Pilnas tvarkaraštis',
  msg_sidebar_my_availability: 'Mano užimtumas',
  msg_sidebar_users_availability: 'Vartotojų užimtumas',
  msg_sidebar_game_nominations: 'Rungtynių paskyrimai',
  msg_sidebar_unconfirmed_games: 'Nepatvirtintos rungtynės',
  msg_sidebar_schedule_export: 'Tvarkaraščio eksportavimas',
  msg_sidebar_invoices: 'Sąskaitos',

  msg_sidebar_availability_page: 'Užimtumas',
  msg_sidebar_documents_page: 'Dokumentai',
  msg_sidebar_journal_page: 'Žiniaraštis',
  msg_sidebar_journal_my: 'Mano žiniaraštis',
  msg_sidebar_journal_ranking: 'Reitingai',
  msg_sidebar_journal_my_ranking: 'Mano Reitingai',
  msg_sidebar_journal_my_expenses: 'Mano išlaidos',
  msg_sidebar_journal_users_ranking: 'Vartotojų reitingai',
  msg_sidebar_journal_export: 'Eksportavimas',
  msg_sidebar_reports: 'Ataskaitos',

  //Labels
  msg_label_game_info: 'Rungtynių informacija',
  msg_label_new_game: 'Naujos rungtynės',
  msg_label_edit_game: 'Redaguoti rungtynes',
  msg_label_game_nominations: 'Rungtynių paskyrimai',
  msg_label_game_evaluations: 'Rungtynių ataskaitos',
  msg_label_game_expenses: 'Rungtynių išlaidos',
  msg_label_game_driver: 'Vairuotojas',
  msg_label_edit_user: 'Redaguoti vartotoją',
  msg_label_new_user: 'Naujas vartotojas',
  msg_label_user_info: 'Vartotojo informacija',
  msg_label_league_info: 'Lygos informacija',
  msg_label_edit_league: 'Redaguoti lygą',
  msg_label_new_league: 'Nauja lyga',
  //Fields
  msg_field_password: 'Slaptažodis',
  msg_field_email: 'El. paštas',
  msg_field_league: 'Lyga',
  msg_field_date: 'Data',
  msg_field_name: 'Pavadinimas',
  msg_field_file: 'Failas',
  msg_field_month_select: 'Mėnesis',

  msg_field_referee_ranking: 'Teisėjų reigingas',
  msg_field_secretary_score_ranking: 'Sekretoriaus reitingas reitingas',
  msg_field_secretary_statistics_ranking: 'Statistika - PC reitingas',
  msg_field_secretary_time_ranking: 'Pagrindinė švieslentė reitingas',
  msg_field_commissioner_ranking: 'Komisaro reitingas',
  msg_field_delegate_ranking: 'Delegato reitingas',
  msg_field_secretary_4_ranking: 'Laikininkas reitingas',
  msg_field_secretary_5_ranking: 'Laikininkas 24sek. reitingas',
  msg_field_secretary_statistics_2_ranking: 'Statistika 1 reitingas',
  msg_field_secretary_statistics_3_ranking: 'Statistika 2 reitingas',
  msg_field_secretary_information_ranking: 'Informatorius reitingas',
  msg_field_commissioner_remotely_ranking: 'Rungtynių analitikas reitingas',
  msg_field_referee_appointment_ranking: 'Teisėjo paskyrimo reitingas',
  msg_field_secretary_appointment_ranking: 'Sekretoriaus paskyrimo reitingas',
  msg_field_trip_ranking: 'Kelionės reitingas',
  msg_field_driver_ranking: 'Vairuotojo reitingas',
  msg_field_referee_one_ranking: 'Vyr. teisėjo reitingas',
  msg_field_referee_two_ranking: 'Teisėjo reitingas',
  msg_field_referee_three_ranking: 'Teisėjo reitingas',

  //Game
  msg_game_field_game_number: 'Rungtynių numeris',
  msg_game_field_team1: 'Šeimininkai',
  msg_game_field_team2: 'Svečiai',
  msg_game_field_tv_broadcast: 'TV transliacija',
  msg_game_field_teams: 'Komandos',
  msg_game_field_address: 'Adresas',
  msg_game_field_modified: 'Pasikeitė informacija?',
  msg_game_field_canceled: 'Atšaukta?',
  msg_game_field_modified_text: 'Kuri rungtynių informacija pasikeitė?',
  msg_game_field_commissioner_remotely: 'Rungtynių analitikas?',

  //User type in event
  msg_user_type_referee_one: 'Vyr. teisėjas',
  msg_user_type_referee_two: 'Teisėjas',
  msg_user_type_referee_three: 'Teisėjas',

  msg_user_type_secretary_score: 'Sekretorius',
  msg_user_type_secretary_time: 'Pagrindinė švieslentė',
  msg_user_type_secretary_4: 'Laikininkas',
  msg_user_type_secretary_5: 'Laikininkas 24sek.',
  msg_user_type_secretary_statistics: 'Statistika - PC',
  msg_user_type_secretary_statistics_2: 'Statistika 1',
  msg_user_type_secretary_statistics_3: 'Statistika 2',
  msg_user_type_secretary_information: 'Informatorius',

  msg_user_type_delegate: 'Delegatas',
  msg_user_type_commissioner: 'Komisaras',
  msg_user_type_referee: 'Teisėjas',
  msg_user_type_administration: 'Administracija',
  msg_user_type_secretary: 'Sekretorius',

  //Header
  msg_sidebar_button_add_game: 'Pridėti rungtynes',
  msg_sidebar_button_game_upload: 'Rungtynių įkėlimas',
  msg_sidebar_button_add_post: 'Pridėti naujieną',
  msg_unconfirmed_games_header: 'Nepatvirtintos rungtynės',
  msg_full_schedule_export_header: 'Tvarkaraščio eksportavimas',
  msg_game_nomination_log_title: 'Istorija',
  msg_game_logs_title: 'Istorija',

  //Toasts
  msg_toast_saved_successful: 'Išsaugota sėkmingai',
  msg_toast_updated_successful: 'Atnaujinta sėkmingai',
  msg_toast_deleted_successful: 'Ištrinta sėkmingai',
  msg_toast_error_occurred: 'Įvyko klaida, mėginkite dar kartą.',
  //Appointment
  msg_appointment_status_pending: 'Nepatvirtinta',
  msg_appointment_status_approved: 'Tinka',
  msg_appointment_status_declined: 'Netinka',

  //User
  msg_user_form_data_fields: 'Vartotojo duomenys',
  msg_user_form_contact_fields: 'Vartotojo kontaktai',
  msg_user_form_settings_fields: 'Nustatymai',
  msg_user_form_invoice_fields: 'Sąskaitos duomenys',
  msg_user_form_info_fields: 'Informacija',
  msg_user_first_last_name: 'Vardas, Pavardė',
  msg_user_field_first_name: 'Vardas',
  msg_user_field_last_name: 'Pavardė',
  msg_user_field_phone: 'Telefonas',
  msg_user_field_address: 'Adresas',
  msg_user_field_email: 'El. paštas',
  msg_user_field_category: 'Kategorija',
  msg_user_field_is_association_member: 'Asociacijos narys?',
  msg_user_field_person_code: 'Asmens kodas',
  msg_user_field_account_number: 'Sąskaita',
  msg_user_field_daily_mail: 'Kasdieninis priminimas apie rungtynes',
  msg_user_field_invoice_serial: 'Sąskaitos serija',
  msg_user_field_invoice_number: 'Sąskaitos numeris',
  msg_user_field_invoice_seller_info: 'Sąskaitos pardavėjo informacija',
  msg_user_field_suspended_until: 'Suspenduotas iki',
  msg_user_field_user_type: 'Tipas',

  msg_user_category_none: 'Be kategorijos',
  msg_user_category_fiba: 'FIBA',
  msg_user_category_lkl: 'LKL',
  msg_user_category_nkl: 'NKL',
  msg_user_category_rkl: 'RKL',
  msg_user_category_national: 'Nacionalinė',

  //Modal
  msg_modal_close_button_aria_label: 'Uždaryti',

  msg_user_type_display_filter_all: 'Rodyti visus įrašus',
  msg_user_type_display_filter_refs: 'Teisėjus',
  msg_user_type_display_filter_none: 'Nieko',
  msg_user_type_display_filter_persons: 'Kitus dalyvius',
  msg_user_type_display_filter_secretary: 'Sekretoriato darbuotojus',
  msg_filter_user_display_type: 'Rodyti',

  //Documents
  msg_documents_header: 'Dokumentai',
  msg_edit_document_category: 'Redaguoti dokumentų kategoriją',
  msg_create_document_category: 'Nauja dokumentų kategorija',
  msg_create_document: 'Įkelti dokumentą',

  msg_form_dropzone_file_rejected: 'Neteisingas failas. Klaida: {{reason}}',
  msg_form_dropzone_choose_file_button: 'Pasirinktite failą',

  //Journal
  msg_user_journal_header: 'Vartotojo mėnesio žiniaraštis',
  msg_journal_total_games: 'Viso rungtynių',
  msg_journal_total_ranking: 'Rungtynių reitingas',
  msg_journal_trip_ranking: 'Kuro išlaidos',
  msg_user_rankings_header: 'Reitingai',
  msg_users_rankings_header: 'Vartotojų reitingai',

  msg_expenses_export: 'Išlaidų eksportavimas',

  msg_label_game_import: 'Informacija apie įkėlimą',
  msg_label_game_import_form: 'Rungtynių įkėlimas',

  msg_label_game_import_info_1: 'Į sistemą galite įkelti rungtynes aprašytas TSV formatu.',
  msg_label_game_import_info_2:
    'Labai svarbu, kad kiekvienas laukelis būtų savo vietoje. Taip pat, kad stulpelius skirtų vienas Tab mygtuko paspaudimas',
  msg_label_game_import_info_3: 'TSV bylos formatas:',
  msg_label_game_import_info_4:
    'Lygos kodas  Rungtynių numeris Data (2012-05-06) Laikas (18:45)  Komanda Komanda Adresas',
  msg_label_game_import_info_5: 'Lygos kodas   Data (2012-05-06)   Laikas (18:45)  Komanda Komanda Adresas',
  msg_label_game_import_info_6:
    'Labai svarbu, naudotumėte teksto laukeliuose nebūtų užsenietiškų kabučių. Vietoje jų reikia naudoti Lietuviškas - „“',
  msg_label_game_import_info_7: 'Rungtynių įkelimą galima padaryti taip',

  msg_label_game_import_info_8: 'Sukuriame Excel bylą su rungtynėmis',
  msg_label_game_import_info_9: 'Apsilankome <googleLink>Google dokumentuose</googleLink>',
  msg_label_game_import_info_11: 'Sukuriame naują darbaknygę (Create > Spreadsheet)',

  msg_label_game_import_info_12: 'Nokopijuojame duomenis iš excel bylos į naujai sukurtą darbaknygę',
  msg_label_game_import_info_13:
    'Pasirenkame Byla (File) > Atsisiųsti kaip (Download as) > Paprastas tekstas (Plain text)',
  msg_label_game_import_info_14: 'Gautą bylą įkeliame į sistemą',
  msg_import_successful_rows_header: 'Sėkmingai įkeltos eilutės',
  msg_import_failed_rows_header: 'Nesėkmingai įkeltos eilutės',
  msg_game_import_edit_league: 'Redaguoti lygą',
  msg_game_import_edit_game: 'Redaguoti rungtynes',
  msg_game_import_stats_total: 'Išviso eilučių: {{count}}',
  msg_game_import_stats_success: 'Sėkmingai įkelta eilučių: {{count}}',
  msg_game_import_stats_error: 'Klaidingų eilučių: {{count}}',

  msg_label_created_at: 'Sukurta',
  msg_label_updated_at: 'Atnaujinta',

  msg_filter_league_type: 'Lygos tipas',

  msg_button_edit_trip_ranking: 'Redaguoti kelionės reitingą',
  msg_assign_game_trip_ranking: 'Kelionės reitingas',
  msg_button_preview: 'Peržiūrėti',
  msg_journal_export_header: 'Žiniaraščiu eksportavimas',

  msg_journal_export_by_league: 'Mėnesio žiniaraštis (Pagal lygas)',
  msg_journal_export_by_league_group: 'Mėnesio žiniaraštis (Pagal lygų grupes)',
  msg_journal_export_league_game_expenses: 'Išlaidos pagal lygų grupes',
  msg_journal_export_balance: 'Balanso žiniaraštis',
  msg_journal_export_total: 'Mėnesio žiniaraštis',
  msg_journal_export_referee_game_cost: 'Mėnesio tvarkaraštis su teisėjų reitingais',
  msg_journal_export_game_cost: 'Mėnesio tvarkaraštis su reitingais',

  msg_mail_for_users_header: 'Laiškas sistemos vartotojams',
  msg_field_subject: 'Tema',
  msg_mail_for_users_success: 'Laiškas išsiųstas',
  msg_user_dropdown_email_to_users: 'Laiškas vartotojams',
  msg_user_dropdown_logout: 'Atsijungti',
  msg_user_dropdown_edit_user_profile: 'Redaguoti profilį',
  msg_user_dropdown_change_password: 'Pakeisti slaptažodį',
  msg_label_profile_edit: 'Redaguoti profilį',
  msg_label_change_password: 'Slaptažodžio keitimas',
  msg_forgot_password_change_success: 'Slaptažodis pakeistas',

  msg_report_team_referee: 'Komandų-teisėjų ataskaita',
  msg_report_full_schedule: 'Rungtynių tvarkaraštis',
  msg_report_confirm_cancel_report: 'Atsisakymų/suspendavimų ataskaita',
  msg_report_league_people: 'Lygų ir personalo ataskaita',
  msg_report_team_expenses: 'Komandų išlaidų ataskaita',

  //Invoices
  msg_invoices_header: 'Sąskaitos',
  msg_invoice_name: 'Sąskaita',
  msg_label_new_invoice: 'Nauja sąskaita',
  msg_invoice_form_data_fields: 'Sąskaitos informacija',
  msg_invoice_field_serial: 'Sąskaitos serija ',
  msg_invoice_field_number: 'Sąskaitos numeris',
  msg_invoice_field_date: 'Sąskaitos data',
  msg_invoice_field_seller: 'Pardavėjas',
  msg_invoice_field_buyer: 'Pirkėjas',
  msg_invoice_form_item_fields: 'Įtraukti',
  msg_confirm_invoice_modal_title: 'Sąskaitos patvirtinimas',

  msg_invoice_generated_download: 'Sugeneruotą sąskaita galite pasisiųsti <download>čia</download>',
  msg_invoice_data_correct_confirm_text: 'Jei sąskaitos informacija teisinga, patvirtinkite',
  msg_button_confirm_invoice: 'Patvirtinti sąskaitą',
  msg_button_edit_invoice: 'Redaguoti sąskaitą',
  msg_field_driver: 'Vairuotojas',
  msg_field_amount: 'Kiekis',
  msg_field_user: 'Vartotojas',
  msg_create_expense_title: 'Pridėti išlaidas',
  msg_no_permissions_title: 'Neturite teisės peržiūrėti šį puslapį',

  gameEvaluationGoodBadThings: 'Esminių sezono akcentų įgyvendinimas',
  gameEvaluationGoodThings: 'Gerai įgyvendinta šiose rungtynėse',
  gameEvaluationBadThings: 'Būtina pagerinti kitose rungtynėse',
  gameEvaluationReview: 'Rungtyniu apibendrinimas',
  videoSituations: 'Situacijos',
  videoSituation: 'Epizodas {{number}}',
  fieldTime: 'Laikas',
  fieldQuarter: 'Kėlinys',
  fieldPriority: 'Atsakomybė',
  fieldComment: 'Komentaras',
  fieldEvaluation: 'Teisinga/neteisinga/diskutuotina',
  fieldDecision: 'Sprendimas (pražanga, pažeidimas, kita)',
  fieldEvaluationHasVideo: 'Video situacija?',
  '1_quarter': '1 kėlinys',
  '2_quarter': '2 kėlinys',
  '3_quarter': '3 kėlinys',
  '4_quarter': '4 kėlinys',
  '1_overtime': '1 pratęsimas',
  '2_overtime': '2 pratęsimas',
  '3_overtime': '3 pratęsimas',
  priority_center: 'Centrinis (Center) teisėjas',
  priority_lead: 'Vedantysis(Lead) teisėjas',
  priority_trail: 'Vedamasis(Trail) teisėjas',
  priority_double: 'Dviguba atsakomybė',

  video_evaluation_correct: 'Teisingas švilpukas',
  video_evaluation_incorrect: 'Neteisingas švilpukas',
  video_evaluation_debatable: 'Diskutuotina',
  video_evaluation_correct_no_call: 'Teisingas nefiksavimas',
  video_evaluation_incorrect_no_call: 'Neteisingas nefiksavimas',

  decision_out_of_bounds: 'Užribis, išmetimas',
  decision_dribbling: 'Kamuolio varymas (driblingas)',
  decision_traveling: 'Žingsniai',
  decision_3sek: '3 sek.',
  decision_5sek: 'Arti dengiamas žaidėjas (5 sek.)',
  decision_8sek: '8 sek.',
  decision_backcourt_violation: 'Vidurio linija',
  decision_foot_violation: 'Žaidimas koja',
  decision_goaltending_interference: 'Neleistinas kamuolio lietimas/Trukdymas įmetimui (goaltending/interference)',
  decision_hand_ball: 'Ginčas',
  decision_ball_control: 'Kamuolio valdymas',
  decision_holding: 'Laikymas (holding)',
  decision_blocking: 'Neteisėta užtvara, Blokavimas be kamuolio (Blocking, illegal screen)',
  decision_block_change: 'Blokavimas/Įsirėžimas (su kamuoliu) (Block/Charge)',
  decision_pushing: 'Stūmimas (pushing)',
  decision_handchecking: 'Prilaikymas ranka (Handchecking)',
  decision_illegal_use_of_hands: 'Neteisėtas veiksmas ranka (smūgis) (Illegal use of hands)',
  decision_illegal_contact_to_hand: 'Neteisėtas kontaktas į ranką (metant) (illegal contact to hand)',
  // hooking: 'Užkabinimas (Hooking)',

  decision_swinging_the_elbow: 'Perdėtas mostas alkūne (Excessive swinging the elbow)',
  decision_hit_to_head: 'Smūgis į galvą (Hit to the head)',
  decision_aos: 'AOS nustatymas, metiko apsauga',
  decision_flop: 'Vaidyba',
  decision_technical_foul: 'Techninė, diskvalifikacinė pražanga',
  decision_verticality: 'Vertikalumas',
  decision_lpp: 'Žaidimas ant ūselio (LPP)',
  decision_protect_the_shooter: 'Metiko apsauga (Protect the shooter)',

  decision_participant_controll: 'Dalyvių, suolelio elgesio kontrolė…',
  decision_administration: 'Administravimas (keitimai, SPS procedūra,kt.)',
  decision_time_control: 'Žaidimo laiko kontrolė',
  decision_shot_clock_control: 'Atakos laiko kontrolė',
  decision_team_work: 'Komandinis pozicionavimas, L, C, T',
  decision_double_call: 'Nereikalingi dvigubi, trigubi švilpukai, AOR',
  decision_rotation: 'Rotacijos',
  decision_missing_help: 'Nesuteikta pagalba',
  decision_individual_position: 'Individualus pozicionavimas',

  decision_unsportsmanlike_foul: 'Nesportinė pražanga',
  decision_hooking: 'Laikymas (Hooking)',
  decision_no_call: 'Neužfiksuota (No call)',
  decision_out_of_bounds_v2: 'Užribis, vidurio linija',
  decision_time_control_v2: 'Atakos, rungtynių laikas',
  decision_time_3_5_8_sec_v2: '3sek, 5sek, 8sek, ginčas',

  decision_violations_other_v2: 'Kita (neštas, dvigubas varymas, trukdymas įmetimui ir kt.',

  decision_blocking_v2: 'Žaidėjas su kamuoliu (blokavimas/įsirėžimas)',
  decision_foul_player_with_ball_v2: 'Pražangos prieš žaidėją su kamuoliu (illegal hands, laikymas, kt.)',
  decision_foul_without_ball_v2: 'Pražangos be kamuolio (laikymas, blokavimas,kita)',
  marginal_calls_v2: 'Marginal calls',
  decision_unsportsmanlike_foul_v2: 'UF,DF',

  decision_discipline_v2: 'Disciplina (AOR, pagalba, 2-gubi, 3-gubi švilukai)',
  decision_mechanics_other_v2: 'Kita (IOT, ...)',
  decision_game_control2_v2: 'OW,TF už elgesį, suolelio kontrolė, komunikavimas',
  decision_looks_v2: 'Fizinis pasirengimas ir išvaizda (svoris, greitis, ištvermė, apranga, elgsena)',
  msg_label_evaluation: 'Rungtynių analizė',

  exportShortEvaluation: 'Eksportuoti trumpą analizę',
  exportRefereeEvaluation: 'Eksportuoti teisėjų įvertinimą',
  exportEvaluation: 'Eksportuoti analizę',

  refereeEvaluationParameterFouls: 'Pražangos',
  refereeEvaluationParameterGameControl: 'Rungtynių valdymas',
  refereeEvaluationParameterViolations: 'Pažeidimai',
  refereeEvaluationParameterMechanics: 'Mechanika',
  refereeEvaluationParameterLooks: 'Fizinis pasirengimas',
  gameRefereeEvaluationByParams: 'Teisėjų vertinimo kriterijai',
  refereeEvaluationParameterAgg: 'Bendras įvertinimas',
  gameEvaluationRefereeEvaluation: 'Teisėjų darbo vertinimas',
  ranking_very_good: 'Labai gerai',
  ranking_good: 'Gerai',
  ranking_satisfied: 'Patenkinamai',
  ranking_unsatisfied: 'Nepatenkinamai',
  msg_sidebar_videos_page: 'Video',
  msg_videos_header: 'Video',
  msg_field_description: 'Aprašymas',
  msg_sidebar_admin_page: 'Administravimas',
  msg_confirm_email_send: 'Ar tikrai norite išsiųsti?',
  msg_toast_sent_successful: 'Išsiųsta',
  msg_button_send_trip_info: 'Išsiųsti kelionės informaciją',
  msg_button_send: 'Išsiųsti',
  msg_trip_info_modal_title: 'Kelionės informacija',

  msg_tooltip_edit: 'Redaguoti',
  msg_tooltip_preview: 'Peržiūrėti',
  msg_tooltip_history: 'Istorija',
  msg_tooltip_delete: 'Ištrinti',
  msg_tooltip_mail_for_game_users: 'Išsiųsti laišką rungtynių dalyviams',
  msg_tooltip_game_evaluation: 'Rungtynių ataskaitos',
  msg_tooltip_journal: 'Vartotojo žiniaraštis',
  msg_tooltip_availability: 'Vartotojo užimtumas',
  msg_tooltip_add_to_calendar: 'Pridėti prie kalendoriaus',

  msg_trip_ranking: 'Už kelionę',

  msg_dashboard_global_statistics_games_count: 'Rungtynės',
  msg_dashboard_global_statistics_games_count_this_season: 'Rungtynės šį sezoną',
  msg_dashboard_global_statistics_refs_count: 'Teisėjai',
  msg_dashboard_global_statistics_secretary_count: 'Sekretoriatas',
  msg_dashboard_global_statistics_commissioners_count: 'Komisarai',
  msg_dashboard_global_statistics_leagues_count: 'Lygos',

  msg_dashboard_my_games_count: 'Rungtynių šį mėnesį',
  msg_dashboard_my_ranking: 'Mėnesio reitingas',

  msg_suspended_until: 'Suspenduotas iki',
  msg_user_is_not_available_for_appointment: 'Užsiėmęs',

  msg_label_balance_import: 'Balanso įkėlimas',
  msg_label_balance_import_info: 'Įkėlimo informacija',
  msg_label_balance_import_form: 'Balanso įkėlimas',
  msg_journal_balance_ranking: 'Likutis',
  msg_label_balances_header: 'Balansai',
  msg_sidebar_button_balance: 'Balansai',

  msg_journal_prev_month_balance: 'Praėjusio mėn. likutis',
  msg_journal_cache_pay: 'Grynais',
  msg_journal_transfer: 'Pavedimu',

  msg_label_balance_import_info_1:
    'Į sistemą galite įkelti vartotojus aprašytus CSV formatu. <bold> Labai svarbu, kad kiekvienas laukelis būtų savo vietoje. Taip pat, kad stulpelius skirtų ; (kabliataškis) </bold> ',
  msg_label_balance_import_info_2: 'CSV bylos formatas:  <bold>Vardas;Pavarde;Mėnesis;Metai;Reikšmė</bold>',

  //Tests
  msg_tests_header: 'Testai',
  msg_test_field_completed_until: 'Sprendimus pateikti iki',
  msg_tooltip_solve: 'Spręsti',
  msg_tooltip_answers: 'Atsakymai',
  msg_tooltip_results: 'Rezultatai',
  msg_tests_new_header: 'Naujas testas',
  msg_tests_edit_header: 'Redaguoti testą',
  msg_test_type_test: 'Testas',
  msg_test_type_survey: 'Apklausa',
  msg_test_info_label: 'Testo informacija',
  msg_test_questions_label: 'Klausimai',
  msg_test_field_question: 'Klausimas',
  msg_test_field_question_type: 'Klausimo tipas',
  msg_test_question_field_video: 'Video nuoroda',
  msg_test_question_type_simple: 'Paprastas',
  msg_test_question_type_video: 'Video',
  msg_test_field_question_answer_explanation: 'Paaiškinimas',
  msg_test_question_answer: 'Atsakymas',
  msg_test_question_answer_correct: 'Teisingas',
  msg_test_field_enabled: 'Įjungta',
  msg_button_add_answer: 'Pridėti atsakymą',
  msg_button_add_question: 'Pridėti klausimą',
  msg_sidebar_tests_page: 'Testai',
  msg_league_group_field_balance_calculation_amount: 'Balanso skaičiavimo suma',
  msg_league_group_field_balance_calculation_amount_secretary: 'Balanso skaičiavimo suma sekretoriatui',

  msg_tests_solve_header: 'Spręsti testą',
  msg_user_export: 'Eksportuoti',
  msg_user_export_all: 'Eksportuoti visus',

  msg_add_to_calendar_modal_title: 'Pridėti į kalendorių',
} as const;
